import React, {
  useState,
  useCallback,
  createContext,
  useReducer,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { getCart, updateCart, submitOrderToFirestore, logCrashErrors } from "../firestore";
import { formatDate, formatTimestamp } from "../helpers";
import { AuthContext } from "./AuthContext";
import { EventContext } from "./EventContext";
import { cartReducer, SET, UPDATE_TOTAL } from "../reducers/CartReducer";
import updateEventPayed from "../updateUserEvent";
import updateProductAvailability from "../updateProductAvailability";
import { firestore } from "../firebaseConfig";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const cartInitializer = () => {
    return {
      products: {},
    };
  };
  const { user, hasAccess } = useContext(AuthContext);
  const {
    products,
    eventDocID,
    eventID,
    budget,
    dropShipCharge,
    forceDropShip,
    startDate,
    endDate,
    lastOrderDate,
    customer,
    customUnits,
    promiseDate,
    poNumberPrefix,
    eventData,
    addressUser
  } = useContext(EventContext);
  const [cartState, cartDispatch] = useReducer(cartReducer, cartInitializer);
  const addressRef = useRef();
  const cartRef = useRef();

  const [dropShip, setDropShip] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [overMax, setOverMax] = useState(false);
  const [overBudget, setOverBudget] = useState(false);
  const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
  const history = useHistory();
  

  const overlay = document.getElementsByClassName("darkOverlay")[0];
  const active = "active";

  const scrollToCart = useCallback(() => cartRef.current.scrollIntoView(), []);
  const scrollToAddress = useCallback(
    () => addressRef.current.scrollIntoView(),
    []
  );
  const scrollToFirstError = useCallback(() => {
    const errorElement = document.getElementsByClassName("invalid-field")[0];

    if (errorElement) {
      errorElement.scrollIntoView();
    }
  }, []);

  // Dark overlay on submission
  useEffect(() => {
    if (submitting) overlay.classList.add(active);
    else overlay.classList.remove(active);
  }, [overlay, submitting]);

  useEffect(() => {
    setDropShip(forceDropShip);
  }, [forceDropShip]);
 

  const submitOrder = useCallback(
    async (dropShipAddress) => {
      // Check for empty monogram
      const cartProducts = Object.values(cartState.products);
      for (const product of cartProducts) {
        const monogram = product.embroidery?.tape?.monogram;
        if (!monogram) continue;
        if (
          !monogram.topText ||
          monogram.topText === "" ||
          !monogram.textStyle ||
          monogram.textStyle === ""
        ) {
          alert(
            "Please fill out your monogram configuation or remove the monogram."
          );
          return;
        }
      }
      
      if (!!user && dropShip && !dropShipAddress) {
        alert(
          "There was an error saving your drop ship address. Please contact a site administrator."
        );
        logCrashErrors({"CartContext-line:105": user, dropShip, addres: user.address})
        return
      }

      setSubmitting(true);
      try {
        const orderEventData = {
          eventDocID,
          promiseDate,
          poNumberPrefix,
          dropShip,
          dropShipAddress: dropShip ? dropShipAddress : null,
          dropShipCharge,
          customUnits,
          customer,
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
          lastOrderDate: formatTimestamp(lastOrderDate),
          orderDate: formatDate(new Date()),
        };
        const cart = await submitOrderToFirestore(
          products,
          cartState.cartID,
          orderEventData,
          user
        );
        
        if (!products) {
          throw new Error("No products were found in the cart.");
        }
        cartDispatch({ type: SET, state: cart });
        await updateEventPayed(user.uid, cartState.total, user);
        await updateProductAvailability(cartProducts, eventData.productList);
        history.push(`/${eventID}/thankyou`);
        
        setSubmitting(false);
      } catch (error) {
        alert(error);
        console.log(`Error submitting order: ${error}`);
        setSubmitting(false);
      }
    },
    [
      user,
      products,
      eventID,
      eventDocID,
      poNumberPrefix,
      promiseDate,
      dropShip,
      dropShipCharge,
      customUnits,
      customer,
      startDate,
      endDate,
      lastOrderDate,
      history,
      cartState,
      cartDispatch,
    ]
  );


  const payedStripe = async (firstName, lastName, amountPaid, email, eventID, paymentID) => {
    await firestore.collection("payment-reports").add({
      firstName: firstName,
      lastName: lastName,
      amount: amountPaid,
      email: email,
      createdAt: new Date(),
      eventID: eventID,
      paymentID: paymentID
    })
  }
    

  // Set the initial state of the user's cart
  useEffect(() => {
    if (
      !eventID ||
      !eventDocID ||
      !user ||
      !user.uid ||
      !hasAccess ||
      !cartDispatch ||
      typeof budget === "undefined"
    )
      return;
    // Check if the event uses universal or individual budgets
    getCart(user, budget, eventDocID)
      .then((result) => {
        cartDispatch({
          state: {
            cartID: result.cartID,
            budget: result.budget,
            balance: result.balance,
            total: result.total,
            payPalApplied: result.payPalApplied ? result.payPalApplied : 0,
            products: !!result.cartProducts ? result.cartProducts : {},
          },
          type: SET,
        });
      })
      .catch((error) => {
        console.log(`Error fetching cart: ${error}`);
      });
  }, [eventID, eventDocID, user, budget, cartDispatch, hasAccess]);

  useEffect(() => {
    if (!cartState || !cartState.cartID) return;
    updateCart(cartState);
  }, [cartState]);

  useEffect(() => {
    if (!products || !cartState.products || !Object.keys(products) === 0)
      return;
    cartDispatch({ type: UPDATE_TOTAL, products, customUnits });
  }, [cartState.products, products, customUnits]);

  return (
    <CartContext.Provider
      value={{
        cartState,
        cartDispatch,
        addressRef,
        cartRef,
        submitting,
        setSubmitting,
        submitOrder,
        scrollToFirstError,
        scrollToCart,
        scrollToAddress,
        dropShip,
        setDropShip,
        loading,
        setLoading,
        overMax,
        setOverMax,
        overBudget,
        setOverBudget,
        showDeliveryOptions,
        setShowDeliveryOptions, 
        payedStripe        
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
